.main {
    height: calc(100vh - 8rem);
    background-image: url('../../../content/images/background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100vw calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-head {
    font-size: 4.5rem;
}

.search-bar {
    width: 50rem;
    font-size: 1.1rem;
    padding-left: 1rem;
    height: 3rem;
}

.search-button {
    height: 3rem;
    width: 3rem;
}

.search-button-img {
    height: 1.8rem;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .main {
        background-size: auto;
        height: calc(100vh - 5.5rem);
        width: 100vw;
    }
    .main-head {
        font-size: 3rem;
        text-align: center;
    }
    .search-bar {
        height: 2rem;
        width: 75vw;
    }
    .search-button {
        height: 2rem;
        width: 2.5rem;
    }
    .search-button-img {
        height: 1.3rem;
    }
}