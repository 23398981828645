.my-nav {
    height: 8rem;
    background-color: white;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
}

.my-nav-logo {
    height: 3rem;
}

.my-nav-links {
    margin-left: 2rem;
}

.my-nav-link {
    font-size: 1rem;
    margin-left: .5rem;
    margin-right: .5rem;
    text-decoration: none;
    font-weight: bold;
}

.nav-button {
    display: none;
}


/* mobile */

@media only screen and (max-width: 600px) {
    .my-nav-logo {
        height: 1.5rem;
    }
    .my-nav {
        height: auto;
        flex-direction: column;
        padding: 0;
        padding-top: .5rem;
        padding-bottom: 1rem;
        transition: all 1s;
    }
    .my-nav-links {
        display: flex;
        margin: 0;
        flex-direction: column;
        align-items: center;
        transition: all .3s;
    }
    .my-nav-links-open {
        height: 5.5rem;
    }
    .my-nav-links-close {
        height: 0;
    }
    .nav-button {
        display: inline-block;
        background-color: white;
        border: none;
    }
    .nav-manager {
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
}